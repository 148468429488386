import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import { useReportSentData } from '../hooks'
import { ReportSentContent } from './ReportSentContent'
import { SchoolSentCollapse } from './SchoolSentCollapse'

const fragments = {
  ReportSentFreeSchool_publicReportSource: graphql(`
    fragment ReportSentFreeSchool_publicReportSource on PublicReportSource {
      id

      ...useReportSentData_publicReportSource
    }
  `),
}

type ReportSentFreeSchoolProps = {
  publicReportSource: FragmentType<typeof fragments.ReportSentFreeSchool_publicReportSource>
}

export const ReportSentFreeSchool = ({
  publicReportSource: _publicReportSource,
}: ReportSentFreeSchoolProps) => {
  const publicReportSource = getFragmentData(
    fragments.ReportSentFreeSchool_publicReportSource,
    _publicReportSource
  )
  const { reportSentDescription } = useReportSentData(publicReportSource)
  return (
    <ReportSentContent description={reportSentDescription}>
      <SchoolSentCollapse />
    </ReportSentContent>
  )
}
