import { Clipboard } from '@capacitor/clipboard'
import { UntitledIcon } from '@faceup/icons'
import { ulCopy01 } from '@faceup/icons/ulCopy01'
import { Button, Prompt } from '@faceup/ui'
import { LoadingOutlined, Space, Typography, useMessage } from '@faceup/ui-base'
import { Box, SimpleGrid, Stack, UnstyledButton, useMantineTheme } from '@mantine/core'
import { Suspense, lazy, useContext, useState } from 'react'
import { LayoutContext } from '../../../../../Contexts/LayoutContext'
import { ReportFormContext } from '../../../../../Contexts/ReportFormContext'
import { sharedMessages } from '../../../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../../../__generated__'
import { copyTextForOldBrowsers } from '../../../../../utils/helpers'
import { NotifyMe } from './NotifyMe'

const PDFReportPin = lazy(() => import('./PDFReportPin'))

const { Text } = Typography

const messages = defineMessages({
  pinFormPrompt: 'FollowUp.CreateReportForm.pinFormPrompt',
  pinDisclaimer: 'FollowUp.ReportSent.pinDisclaimer',
  pinLabel: 'FollowUp.CheckReport.pinLabel',
  pinCopy: 'Shared.action.copy',
  pinCopied: 'Shared.global.copied',
  checkReport: 'FollowUp.CheckReport.title',
  beforeLeaveDescription: 'FollowUp.ReportSent.beforeLeave.description',
})

const fragments = {
  PinForm_publicReportSource: graphql(`
    fragment PinForm_publicReportSource on PublicReportSource {
      id
      shortLink

      ...NotifyMe_publicReportSource
    }
  `),
}

type PinFormProps = {
  reportSource: FragmentType<typeof fragments.PinForm_publicReportSource>
}

export const PinForm = ({ reportSource: _reportSource }: PinFormProps) => {
  const reportSource = getFragmentData(fragments.PinForm_publicReportSource, _reportSource)
  const { formatMessage } = useIntl()
  const { victimPin, createdAt, tag } = useContext(ReportFormContext)
  const [pinFormPrompt, setPinFormPrompt] = useState(true)
  const { client } = useContext(LayoutContext)
  const theme = useMantineTheme()
  const message = useMessage()

  const savePinToClipboard = async () => {
    // if client is JOBKA, they are unable to copy PIN in webview, that means we need to call a custom function, which they inject into the page
    if (client === 'jobka') {
      if (window?.copyToClipboard) {
        // JOBKA custom function - declaration in `typings.d.ts`
        window?.copyToClipboard({ Text: victimPin ?? '' })
        message.success(formatMessage(messages.pinCopied))
        setPinFormPrompt(false)
        return
      }
      console.error('Function is not implemented')
    }

    try {
      await Clipboard.write({ string: victimPin ?? '' })
      message.success(formatMessage(messages.pinCopied))
      setPinFormPrompt(false)
    } catch {
      const copied = copyTextForOldBrowsers(victimPin ?? '')
      if (copied) {
        message.success(formatMessage(messages.pinCopied))
        setPinFormPrompt(false)
      } else {
        message.error(formatMessage(sharedMessages.apiError))
      }
    }
  }

  return (
    <Box
      sx={{
        border: `1px solid ${theme.colors.primary[1]}`,
        borderRadius: '0.75rem',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.colors.primary[1],
          padding: '1.5rem',
          paddingTop: '3rem',
          paddingInline: '2rem',
          [theme.fn.largerThan('sm')]: {
            paddingInline: '3.5rem',
          },
        }}
      >
        <Stack spacing='xl'>
          <Box>
            <Text strong style={{ textAlign: 'center', display: 'block' }}>
              <FormattedMessage {...messages.pinDisclaimer} />
            </Text>
          </Box>
          <UnstyledButton
            sx={{
              background: '#fff',
              borderRadius: 6,
              paddingTop: 8,
              paddingBottom: 8,
            }}
            onClick={() => savePinToClipboard()}
          >
            <Text
              style={{
                fontSize: 20,
                fontWeight: 600,
                textAlign: 'center',
                display: 'block',
              }}
            >
              {victimPin}
            </Text>
          </UnstyledButton>
          <SimpleGrid
            cols={2}
            breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
            sx={theme => ({
              [theme.fn.largerThan('md')]: {
                marginInline: '2rem',
              },
            })}
          >
            <Button isFullWidth data-cy='report-sent-pin-copy' onClick={() => savePinToClipboard()}>
              <Space>
                <UntitledIcon icon={ulCopy01} />
                <FormattedMessage {...messages.pinCopy} />
              </Space>
            </Button>
            <Button
              isFullWidth
              data-cy='report-sent-pin-download'
              onClick={() => setPinFormPrompt(false)}
            >
              <Suspense
                fallback={
                  <Space>
                    <LoadingOutlined />
                    <FormattedMessage {...sharedMessages.download} />
                  </Space>
                }
              >
                <PDFReportPin
                  pin={victimPin}
                  createdAt={createdAt}
                  shortId={reportSource?.shortLink ?? ''}
                  tag={tag ?? ''}
                />
              </Suspense>
            </Button>
          </SimpleGrid>
        </Stack>
        <Prompt when={pinFormPrompt} message={formatMessage(messages.pinFormPrompt)} />
      </Box>

      <NotifyMe publicReportSource={reportSource} reportPin={victimPin ?? ''} />
    </Box>
  )
}
