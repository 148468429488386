import { AppShell, Aside, Box, Group, MediaQuery, Navbar, useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { type ReactElement, type ReactNode, useContext } from 'react'
import { LayoutContext } from '../../Contexts/LayoutContext'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import {
  type LayoutVariant,
  desktopBreakpoint,
  headerPadding,
  maxAppWidth,
  panelWidth,
} from '../../utils/constants'
import Footer from '../Footer'

const fragments = {
  AppWrapper_config: graphql(`
    fragment AppWrapper_config on CompanyConfig {
      id
      ...Footer_config
    }
  `),
}

type AppWrapperProps = {
  children?: ReactNode
  variant?: LayoutVariant
  headerComponent: ReactElement
  navbarComponent?: ReactElement
  footerComponent?: ReactElement
  config?: FragmentType<typeof fragments.AppWrapper_config>
}

export const AppWrapper = ({
  children,
  variant = 'complex',
  headerComponent,
  navbarComponent,
  footerComponent,
  config: _config,
}: AppWrapperProps) => {
  const config = getFragmentData(fragments.AppWrapper_config, _config)
  const { client } = useContext(LayoutContext)
  const { breakpoints } = useMantineTheme()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints[desktopBreakpoint]})`)

  const showBottomText = client !== 'iframe'

  return (
    <Group position='center'>
      <Box
        sx={{
          maxWidth: maxAppWidth - headerPadding,
          width: '100%',
          paddingBottom: client === 'app' ? 60 : 0,
        }}
      >
        <AppShell
          fixed={!isDesktop}
          styles={theme => ({
            main: {
              [`@media (min-width: ${theme.breakpoints.sm})`]: {
                paddingInline: '6%',
              },
              [`@media (min-width: ${theme.breakpoints.md})`]: {
                paddingInline: '3%',
              },
              [`@media (min-width: ${theme.breakpoints.lg})`]: {
                paddingInline: '9%',
              },
            },
          })}
          padding='xl'
          header={headerComponent}
          aside={
            <MediaQuery smallerThan={desktopBreakpoint} styles={{ display: 'none' }}>
              <Aside
                width={panelWidth[variant]}
                hiddenBreakpoint={desktopBreakpoint}
                hidden
                withBorder={false}
              >
                <Box />
              </Aside>
            </MediaQuery>
          }
          navbar={
            navbarComponent ?? (
              <Navbar
                width={panelWidth[variant]}
                hiddenBreakpoint={desktopBreakpoint}
                withBorder={false}
                p='xs'
                hidden
              >
                <Box />
              </Navbar>
            )
          }
          footer={footerComponent}
        >
          {children}
          {showBottomText && (
            <Box sx={{ paddingTop: isDesktop ? 48 : 40 }}>
              <Footer config={config} />
            </Box>
          )}
        </AppShell>
      </Box>
    </Group>
  )
}
