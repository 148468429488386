import { useQuery } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulCheck } from '@faceup/icons/ulCheck'
import { usXClose } from '@faceup/icons/usXClose'
import { useLocation, useParams } from '@faceup/router'
import { usePageTemplate } from '@faceup/ui'
import { Button, Flex, Typography, useModal, useUiBaseConfig } from '@faceup/ui-base'
import { ChannelPageType, DEFAULT_LANGUAGE } from '@faceup/utils'
import { type ReactNode, useContext, useEffect, useState } from 'react'
import { AppWrapper } from '../../Components/InstitutionLayout/AppWrapper'
import { Header } from '../../Components/InstitutionLayout/components'
import { LanguageContext } from '../../Contexts/LanguageContext'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import { CreateSurveyForm } from './CreateSurveyForm'

const messages = defineMessages({
  previewModalTitle: 'FollowUp.surveys.previewModal.title',
  previewModalDescription: 'FollowUp.surveys.previewModal.description',
  disabledDescription: 'FollowUp.surveys.disabled.description',
  beforeSendButton: 'FollowUp.welcome.startButton',
})

const fragments = {
  SurveySteps_reportSource: graphql(`
    fragment SurveySteps_reportSource on PublicReportSource {
      id
      defaultLanguage
      config {
        ... on SurveyChannelConfiguration {
          id
          ...CreateSurveyForm_surveyChannelConfiguration
        }
      }
      ...UseFilledPage_reportSource
    }
  `),
  UseFilledPage_reportSource: graphql(`
    fragment UseFilledPage_reportSource on PublicReportSource {
      id
      pages(language: $language) {
        ... on ChannelPage {
          id
          type
          content
          title
        }
      }
    }
  `),
  SurveyNotAvailable_reportSource: graphql(`
    fragment SurveyNotAvailable_reportSource on PublicReportSource {
      id
      ...UseFilledPage_reportSource
    }
  `),
}

const query = graphql(`
  query SurveyViewQuery(
    $reportSourceId: PublicReportSourceGlobalId!
    $language: Language!
  ) {
    publicSurvey(reportSourceId: $reportSourceId) {
      id
      defaultLanguage
      isEnabled
      config {
        ... on SurveyChannelConfiguration {
          id
          ...CreateSurveyForm_surveyChannelConfiguration
        }
      }
      ...SurveySteps_reportSource
      ...SurveyNotAvailable_reportSource
      ...Header_reportSource
    }
  }
`)

const SurveyView = () => {
  const { search } = useLocation()
  const { id: surveyId } = useParams()
  const { changeLanguage } = useContext(LanguageContext)
  const params = new URLSearchParams(search)
  const hasPreviewParam = params.has('preview')
  const modal = useModal()
  const [previewParam, setPreviewParam] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (hasPreviewParam) {
        setPreviewParam(true)
      }
    }, 200)
  }, [hasPreviewParam])

  // biome-ignore lint/correctness/useExhaustiveDependencies: modal.confirm will make the modal crop up twice
  useEffect(() => {
    if (previewParam) {
      modal.confirm({
        title: <FormattedMessage {...messages.previewModalTitle} />,
        content: <FormattedMessage {...messages.previewModalDescription} />,
      })
    }
  }, [previewParam])

  const { data } = useQuery(query, {
    variables: { reportSourceId: surveyId ?? '', language: DEFAULT_LANGUAGE },
  })

  const survey = data?.publicSurvey

  useEffect(() => {
    const defaultLanguage = survey?.defaultLanguage
    if (defaultLanguage) {
      changeLanguage(defaultLanguage)
    }
  }, [survey, changeLanguage])

  if (!survey) {
    return null
  }

  return (
    <AppWrapper
      headerComponent={<Header noMenu reportSource={survey} variant='simple-no-back-button' />}
      variant='simple'
    >
      <Flex
        style={{ minHeight: '60vh', textAlign: 'center' }}
        align='center'
        justify='center'
        vertical
        gap='20px'
      >
        {survey.isEnabled || hasPreviewParam ? (
          <SurveySteps survey={survey} isPreview={hasPreviewParam} />
        ) : (
          <SurveyNotAvailable survey={survey} />
        )}
      </Flex>
    </AppWrapper>
  )
}

type SurveyStepsProps = {
  survey: FragmentType<typeof fragments.SurveySteps_reportSource>
  isPreview?: boolean
}

const SurveySteps = ({ survey: _survey, isPreview = false }: SurveyStepsProps) => {
  const survey = getFragmentData(fragments.SurveySteps_reportSource, _survey)
  const [currentStep, setCurrentStep] = useState<ChannelPageType>(ChannelPageType.BeforeSend)
  const filledPage = useFilledPage({ survey, step: currentStep })
  const surveyConfig =
    survey.config?.__typename === 'SurveyChannelConfiguration' ? survey.config : null

  if (currentStep === ChannelPageType.BeforeSend) {
    return (
      <>
        <Typography.Title>{filledPage?.title}</Typography.Title>
        <Typography.Text>{filledPage?.content}</Typography.Text>
        <Button
          data-cy='start-survey-button'
          type='primary'
          onClick={() => setCurrentStep(ChannelPageType.FormItems)}
        >
          <FormattedMessage {...messages.beforeSendButton} />
        </Button>
      </>
    )
  }

  if (currentStep === ChannelPageType.FormItems) {
    return (
      surveyConfig && (
        <CreateSurveyForm
          onClickNextStep={() => setCurrentStep(ChannelPageType.AfterSend)}
          isPreview={isPreview}
          surveyConfig={surveyConfig}
          surveyId={survey.id}
          surveyDefaultLanguage={survey.defaultLanguage}
        />
      )
    )
  }

  if (currentStep === ChannelPageType.AfterSend) {
    return (
      <>
        <CircleIcon icon={<UntitledIcon icon={ulCheck} size='40px' color='white' />} />
        <Typography.Title>{filledPage?.title}</Typography.Title>
        <Typography.Text>{filledPage?.content}</Typography.Text>
      </>
    )
  }
  return null
}

type SurveyNotAvailableProps = {
  survey: FragmentType<typeof fragments.SurveyNotAvailable_reportSource>
}

const SurveyNotAvailable = ({ survey: _survey }: SurveyNotAvailableProps) => {
  const survey = getFragmentData(fragments.SurveyNotAvailable_reportSource, _survey)
  const filledPage = useFilledPage({
    survey,
    step: ChannelPageType.BeforeSend,
  })

  return (
    <>
      <CircleIcon icon={<UntitledIcon icon={usXClose} size='40px' color='white' />} />
      <Typography.Title>{filledPage?.title}</Typography.Title>
      <Typography.Text>
        <FormattedMessage {...messages.disabledDescription} />
      </Typography.Text>
    </>
  )
}

type UseFilledPageProps = {
  survey: FragmentType<typeof fragments.UseFilledPage_reportSource>
  step: ChannelPageType
}

const useFilledPage = ({ survey: _survey, step }: UseFilledPageProps) => {
  const survey = getFragmentData(fragments.UseFilledPage_reportSource, _survey)
  const { fillObject } = usePageTemplate()

  const currentPage = survey.pages.find(page =>
    page.__typename === 'Page' ? false : page.type === step
  )

  if (!currentPage) {
    return null
  }

  // @ts-expect-error TODO
  return currentPage.__typename === 'ChannelPage' ? fillObject(currentPage) : undefined
}

const CircleIcon = ({ icon }: { icon: ReactNode }) => {
  const { token } = useUiBaseConfig()
  return (
    <Flex
      style={{
        width: '66px',
        height: '66px',
        borderRadius: '50%',
        background: token.colorPrimary,
      }}
      justify='center'
      align='center'
    >
      {icon}
    </Flex>
  )
}

export default SurveyView
