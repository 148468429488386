import styled from '@emotion/styled'
import { Icon, Layout, Space, useModal } from '@faceup/ui-base'
import { useContext } from 'react'
import { LayoutContext } from '../../Contexts/LayoutContext'
import { ReportFormContext } from '../../Contexts/ReportFormContext'
import CloseIcon from '../../Shared/assets/close-icon.svg?react'
import GreenShieldIcon from '../../Shared/assets/green-shield-icon.svg?react'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import FaceUpLogo from '../../assets/logo-fu.svg'
import NNTBPlainLogo from '../../assets/logo-nntb-plain.svg'
import useConfigForProject from '../../hooks/useConfigForProject'

const { Footer: AntFooter } = Layout

const messages = defineMessages({
  systemSecured: 'FollowUp.Footer.systemSecured',
  systemSecuredAnonymous: 'FollowUp.Footer.systemSecured.anonymous',
  poweredBy: 'FollowUp.Footer.poweredBy',
  anonymousMoreInfoTitle: 'FollowUp.anonymous.moreInfo.title',
  anonymousMoreInfo0: 'FollowUp.anonymous.moreInfo.0',
  anonymousMoreInfo1: 'FollowUp.anonymous.moreInfo.1',
  anonymousMoreInfo2: 'FollowUp.anonymous.moreInfo.2',
  anonymousMoreInfo3: 'FollowUp.anonymous.moreInfo.3',
})

const fragments = {
  Footer_config: graphql(`
    fragment Footer_config on CompanyConfig {
      id
      isBrandingHidden
    }
  `),
}

type FooterProps = {
  config?: FragmentType<typeof fragments.Footer_config>
}

const Footer = ({ config: _config }: FooterProps) => {
  const config = getFragmentData(fragments.Footer_config, _config)
  const { formatMessage } = useIntl()
  const { theme, client } = useContext(LayoutContext)
  const { isAnonymous } = useContext(ReportFormContext)
  const { isNntb } = useConfigForProject()

  const modal = useModal()

  return (
    <Wrapper>
      <Banner>
        <Icon component={GreenShieldIcon} style={{ fontSize: 20, marginRight: 4 }} />

        <div>
          <FormattedMessage
            {...(isAnonymous ? messages.systemSecuredAnonymous : messages.systemSecured)}
            values={{
              modal: content => (
                <ModalLink
                  onClick={() =>
                    modal.info({
                      // we have to use `formatMessage`, because of context
                      styles: {
                        body: {
                          textAlign: 'start',
                        },
                      },
                      icon: false,
                      closable: true,
                      maskClosable: true,
                      centered: true,
                      okButtonProps: { style: { display: 'none' } },
                      closeIcon: <Icon component={CloseIcon} />,
                      title: formatMessage(messages.anonymousMoreInfoTitle),
                      content: (
                        <div>
                          <InfoRow size={14}>
                            <Icon component={GreenShieldIcon} style={{ fontSize: 20 }} />
                            {formatMessage(messages.anonymousMoreInfo0)}
                          </InfoRow>
                          <InfoRow size={14}>
                            <Icon component={GreenShieldIcon} style={{ fontSize: 20 }} />
                            {formatMessage(messages.anonymousMoreInfo1)}
                          </InfoRow>
                          <InfoRow size={14}>
                            <Icon component={GreenShieldIcon} style={{ fontSize: 20 }} />
                            {formatMessage(messages.anonymousMoreInfo2)}
                          </InfoRow>
                          <InfoRow size={14}>
                            <Icon component={GreenShieldIcon} style={{ fontSize: 20 }} />
                            <div>
                              {formatMessage(messages.anonymousMoreInfo3, {
                                link: content => (
                                  <a
                                    href='https://www.torproject.org/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    {content}
                                  </a>
                                ),
                              })}
                            </div>
                          </InfoRow>
                        </div>
                      ),
                    })
                  }
                >
                  {content}
                </ModalLink>
              ),
            }}
          />
        </div>
      </Banner>

      {client !== 'app' && !config?.isBrandingHidden && (
        <FooterWrapper>
          <Space>
            <FormattedMessage {...messages.poweredBy} />
            <a
              href={isNntb ? 'https://www.nntb.cz/' : 'https://www.faceup.com/'}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Logo src={isNntb ? NNTBPlainLogo : FaceUpLogo} alt={theme} />
            </a>
          </Space>
        </FooterWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FooterWrapper = styled(AntFooter)`
  font-size: 12px;
  text-align: center;
  background: none;

  a {
    text-decoration: underline;
    color: #062d46;
  }
`

const Banner = styled.div`
  border-radius: 8px;
  padding: 12px 14px;
  background: #ecfbf3;
  color: #0cce6b;
  max-width: 1280px;
  display: flex;
  align-items: center;
  margin: auto;
  margin-bottom: 10px;
  font-weight: 600;

  div {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

const ModalLink = styled.span`
  color: #0cce6b;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
`

const InfoRow = styled(Space)`
  margin-bottom: 6px;
  align-items: center;
`

const Logo = styled.img`
  max-height: 20px;
  width: auto;
`

export default Footer
