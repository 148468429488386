import { useQuery } from '@apollo/client'
import { Navigate } from '@faceup/router'
import { Plan } from '@faceup/utils'
import { useContext } from 'react'
import { FullScreenLoader } from '../../Components/FullScreenLoader'
import { ReportFormContext } from '../../Contexts/ReportFormContext'
import { UserContext } from '../../Contexts/UserContext'
import { graphql } from '../../__generated__'
import {
  ReportSentFreeSchool,
  ReportSentGeneral,
  ReportSentTestingCompany,
  ReportSentUnregisteredSchool,
} from './components'

const query = {
  ReportSent: graphql(`
    query ReportSentQuery(
      $institutionId: UUID!
      $reportSourceId: PublicReportSourceGlobalId!
    ) {
      publicInstitution(institutionId: $institutionId) {
        id
        isTesting

        billing {
          id
          plan
        }
      }
      publicReportSource(reportSourceId: $reportSourceId) {
        id
        ...ReportSentFreeSchool_publicReportSource
        ...ReportSentGeneral_publicReportSource
      }
    }
  `),
}

const ReportSent = () => {
  const { reportSource: userInstitution } = useContext(UserContext)
  const { victimPin } = useContext(ReportFormContext)

  const { data } = useQuery(query.ReportSent, {
    variables: {
      institutionId: userInstitution?.institutionId ?? '',
      reportSourceId: userInstitution?.defaultFormId ?? '',
    },
  })

  const institution = data?.publicInstitution
  const reportSource = data?.publicReportSource

  if (!victimPin) {
    return <Navigate to={routes => routes.createReport()} replace />
  }

  if (!institution || !reportSource) {
    return <FullScreenLoader />
  }

  if (institution.isTesting) {
    return <ReportSentTestingCompany />
  }

  if (institution?.billing?.plan === Plan.Unregistered) {
    return <ReportSentUnregisteredSchool />
  }

  if (institution?.billing?.plan === Plan.Free) {
    return <ReportSentFreeSchool publicReportSource={reportSource} />
  }
  return <ReportSentGeneral reportSource={reportSource} />
}

export default ReportSent
