import { UntitledIcon } from '@faceup/icons'
import { ulInfoCircle } from '@faceup/icons/ulInfoCircle'
import { List, useMantineTheme } from '@mantine/core'
import { type FragmentType, getFragmentData, graphql } from '../../../../__generated__'
import { useReportSentData } from '../../hooks'
import { ReportSentContent } from '../ReportSentContent'
import { PinForm } from './components'

export const fragments = {
  ReportSentGeneral_publicReportSource: graphql(`
    fragment ReportSentGeneral_publicReportSource on PublicReportSource {
      id

      ...useReportSentData_publicReportSource
      ...PinForm_publicReportSource
    }
  `),
}

type ReportSentGeneralProps = {
  reportSource: FragmentType<typeof fragments.ReportSentGeneral_publicReportSource>
}

export const ReportSentGeneral = ({ reportSource: _reportSource }: ReportSentGeneralProps) => {
  const reportSource = getFragmentData(
    fragments.ReportSentGeneral_publicReportSource,
    _reportSource
  )
  const theme = useMantineTheme()
  const { reportSentDescription } = useReportSentData(reportSource)

  return (
    <ReportSentContent>
      <PinForm reportSource={reportSource} />
      <List
        size='sm'
        icon={<UntitledIcon icon={ulInfoCircle} color={theme.colors.prussianBlue[6]} />}
      >
        <List.Item>{reportSentDescription}</List.Item>
      </List>
    </ReportSentContent>
  )
}
