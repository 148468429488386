import { TextUrlfy } from '@faceup/ui'
import { type ReactNode, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { defineMessages } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import { useCustomValues } from '../../../hooks/useCustomValues'

const messages = defineMessages({
  description: 'Shared.CompanyReportSent.description',
})

const fragments = {
  useReportSentData_publicReportSource: graphql(`
    fragment useReportSentData_publicReportSource on PublicReportSource {
      id

      config {
        ... on FormReportSourceConfiguration {
          id
          reportSentDescription
        }
      }
    }
  `),
}

export const useReportSentData = (
  _reportSource: FragmentType<typeof fragments.useReportSentData_publicReportSource>
) => {
  const reportSource = getFragmentData(
    fragments.useReportSentData_publicReportSource,
    _reportSource
  )
  const customValues = useCustomValues()
  const config =
    reportSource.config?.__typename === 'FormReportSourceConfiguration' ? reportSource.config : null
  const reportSentDescription: ReactNode = useMemo(() => {
    if (customValues?.description) {
      return <TextUrlfy text={customValues.description} />
    }
    if (config?.reportSentDescription) {
      return <TextUrlfy text={config.reportSentDescription} />
    }
    return <FormattedMessage {...messages.description} />
  }, [config?.reportSentDescription, customValues?.description])

  return {
    reportSentDescription,
  }
}
