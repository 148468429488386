import { useMutation } from '@apollo/client'
import { type AnyObject, Form, useForm, yup } from '@faceup/form'
import { Flex, Typography, notification, useMessage } from '@faceup/ui-base'
import { FormItemType, type Language, getTranslation } from '@faceup/utils'
import { useEffect } from 'react'
import { SurveyMultipleChoice, SurveySingleChoice, SurveyTextInput } from '../../Components/Survey'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'

const fragments = {
  CreateSurveyForm_surveyChannelConfiguration: graphql(`
    fragment CreateSurveyForm_surveyChannelConfiguration on SurveyChannelConfiguration {
      id
      formItems {
        id
        type
        isRequired
        order
        labelTranslations {
          translation
          language
        }
        hintTranslations {
          translation
          language
        }
        maxResponses
        minResponses
        maxLength
        options {
          id
          order
          labelTranslations {
            translation
            language
          }
        }
        ...UseCreateYupSchema_formItem
      }
    }
  `),
  UseCreateYupSchema_formItem: graphql(`
    fragment UseCreateYupSchema_formItem on FormItem {
      id
      type
      isRequired
      maxLength
      maxResponses
      minResponses
      options {
        id
      }
    }
  `),
}

const mutations = {
  createSubmission: graphql(`
    mutation CreateSurveySubmission($input: CreateSurveySubmissionInput!) {
      createSurveySubmission(input: $input) {
        createdSubmission {
          id
        }
      }
    }
  `),
}

const messages = defineMessages({
  surveyNotSent: 'FollowUp.surveys.questions.notSent',
  questionsTitle: 'FollowUp.surveys.questions.title',
  isRequired: 'Shared.global.invalidInput',
  fillAllRequiredFields: 'Shared.global.fillAllRequiredFields',
  minLimit: 'FollowUp.surveys.questions.error.minLimit',
  maxLimit: 'FollowUp.surveys.questions.error.maxLimit',
})

type FormItems = FragmentType<typeof fragments.UseCreateYupSchema_formItem>[]

const useCreateYupSchema = (_formItems: FormItems) => {
  const formItems = getFragmentData(fragments.UseCreateYupSchema_formItem, _formItems)
  const { formatMessage } = useIntl()
  const schema = formItems.reduce((accSchema, formItem) => {
    const { type, isRequired, id, maxLength, maxResponses, minResponses } = formItem

    const getValidationForFormItem = () => {
      switch (type) {
        case FormItemType.SimpleText: {
          let simpleTextSchema = yup.string()
          if (maxLength) {
            simpleTextSchema = simpleTextSchema.max(maxLength)
          }
          if (isRequired) {
            simpleTextSchema = simpleTextSchema.required()
          }

          return simpleTextSchema
        }
        case FormItemType.Select:
          return isRequired
            ? yup
                .string()
                .required()
                .oneOf(formItem.options.map(option => option.id))
            : yup.string().oneOf(formItem.options.map(option => option.id))
        case FormItemType.MultiSelect:
          return isRequired
            ? yup
                .array()
                .of(yup.string().oneOf(formItem.options.map(option => option.id)))
                .required()
                .min(
                  minResponses ?? 1,
                  formatMessage(messages.minLimit, { count: minResponses ?? 1 })
                )
                .max(maxResponses ?? Number.POSITIVE_INFINITY, formatMessage(messages.maxLimit))
            : yup
                .array()
                .of(yup.string().oneOf(formItem.options.map(option => option.id)))
                .test(
                  'min-limit',
                  formatMessage(messages.minLimit, { count: minResponses ?? 1 }),
                  values => {
                    const isValid =
                      minResponses === null ||
                      values?.length === 0 ||
                      (!!values?.length && values.length >= minResponses)
                    return isValid
                  }
                )
                .test(
                  'max-limit',
                  formatMessage(messages.maxLimit, { count: maxResponses }),
                  values => {
                    const isValid =
                      maxResponses === null ||
                      values?.length === 0 ||
                      (!!values?.length && values.length <= maxResponses)
                    return isValid
                  }
                )
      }
      return
    }
    const validator = getValidationForFormItem()

    return {
      ...accSchema,
      [id]: validator,
    }
  }, {})

  return yup.object().shape(schema)
}

type CreateSurveyFormProps = {
  onClickNextStep: () => void
  isPreview: boolean
  surveyConfig: FragmentType<typeof fragments.CreateSurveyForm_surveyChannelConfiguration>
  surveyId: string
  surveyDefaultLanguage: Language
}

export const CreateSurveyForm = ({
  onClickNextStep,
  isPreview,
  surveyConfig: _surveyConfig,
  surveyId,
  surveyDefaultLanguage,
}: CreateSurveyFormProps) => {
  const surveyConfig = getFragmentData(
    fragments.CreateSurveyForm_surveyChannelConfiguration,
    _surveyConfig
  )
  const message = useMessage()
  const { formatMessage } = useIntl()

  const formItems = surveyConfig.formItems
  const schema = useCreateYupSchema(formItems)

  const form = useForm<{ [id: string]: AnyObject }>({
    schema,
    afterSubmit: 'persistValues',
  })

  const [createSubmission] = useMutation(mutations.createSubmission, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  useEffect(() => {
    if (Object.keys(form.formState.errors).length > 0 && form.formState.isSubmitted) {
      message.error(formatMessage(messages.fillAllRequiredFields))
    }
  }, [form.formState.errors, form.formState.isSubmitted, formatMessage, message.error])

  return (
    <Flex vertical gap={64} style={{ width: '100%' }}>
      <Flex justify='center'>
        <Typography.Title level={3}>
          <FormattedMessage {...messages.questionsTitle} />
        </Typography.Title>
      </Flex>
      <Form
        buttonsPosition='under-center'
        submitButtonText='send'
        form={form}
        isSuccessMessageDisplayed={false}
        onSubmit={async values => {
          if (isPreview) {
            message.warning(formatMessage(messages.surveyNotSent))
            onClickNextStep()
            return false
          }
          const result = await createSubmission({
            variables: {
              input: {
                reportSourceId: surveyId,
                answers: Object.entries(values)?.map(([key, value]) => ({
                  formItemId: key,
                  values: Array.isArray(value) ? value : [value],
                })),
              },
            },
          })
          if (result.errors) {
            return false
          }
          onClickNextStep()
          return true
        }}
      >
        <Flex vertical gap={64} className='mb-24px text-start'>
          {formItems.map(formItem => {
            const { id, type, options, maxResponses } = formItem
            const label = getTranslation(
              formItem.labelTranslations,
              surveyDefaultLanguage,
              surveyDefaultLanguage
            )
            const hint = getTranslation(
              formItem.hintTranslations ?? [],
              surveyDefaultLanguage,
              surveyDefaultLanguage
            )
            const optionLabels = options.map(option => ({
              label: option.labelTranslations[0]?.translation ?? '',
              value: option.id,
            }))

            switch (type) {
              case FormItemType.SimpleText:
                return (
                  <SurveyTextInput
                    name={id}
                    key={id}
                    label={label}
                    description={hint}
                    control={form.control}
                  />
                )
              case FormItemType.Select:
                return (
                  <SurveySingleChoice
                    key={id}
                    name={id}
                    label={label}
                    description={hint}
                    control={form.control}
                    options={optionLabels}
                  />
                )
              case FormItemType.MultiSelect:
                return (
                  <SurveyMultipleChoice
                    key={id}
                    name={id}
                    label={label}
                    description={hint}
                    control={form.control}
                    options={optionLabels}
                    maxResponses={maxResponses}
                  />
                )
              default:
                return null
            }
          })}
        </Flex>
      </Form>
    </Flex>
  )
}
