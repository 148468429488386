import { Navbar as MantineNavbar, MediaQuery } from '@mantine/core'
import { type FragmentType, getFragmentData, graphql } from '../../../../__generated__'
import { type LayoutVariant, desktopBreakpoint, panelWidth } from '../../../../utils/constants'
import { NavbarContent } from './NavbarContent'

const fragments = {
  InstitutionLayoutNavbar_reportSource: graphql(`
    fragment InstitutionLayoutNavbar_reportSource on PublicReportSource {
      id

      ...InstitutionLayoutNavbarContent_reportSource
    }
  `),
}

type NavbarProps = {
  reportSource: FragmentType<typeof fragments.InstitutionLayoutNavbar_reportSource>
  isOpened: boolean
  variant: LayoutVariant
}

export const Navbar = ({ reportSource: _reportSource, isOpened, variant }: NavbarProps) => {
  const reportSource = getFragmentData(
    fragments.InstitutionLayoutNavbar_reportSource,
    _reportSource
  )
  return (
    <MantineNavbar
      width={panelWidth[variant]}
      hiddenBreakpoint={desktopBreakpoint}
      hidden={!isOpened}
      withBorder={false}
      p='xs'
    >
      <MediaQuery
        largerThan={desktopBreakpoint}
        styles={{
          display: 'none',
        }}
      >
        <MantineNavbar.Section grow my='xs' px='0'>
          <NavbarContent reportSource={reportSource} />
        </MantineNavbar.Section>
      </MediaQuery>
    </MantineNavbar>
  )
}
