import type { Institution } from '@faceup/utils'
import { createContext } from 'react'

export type UserInstitution = {
  institutionId: string
  defaultFormId: string
  type: Institution
  password?: string
}

export enum Permissions {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

export type UserSettings = {
  biometrics?: boolean
}

type State = {
  isAuthenticated: boolean
  login: {
    hasAccount: boolean
    authenticate: (pin: string) => Promise<boolean>
    biometricsAuthentication: () => void
    signUp: (pin: string) => void
  }
  logout: () => void
  reportSource: UserInstitution | null | undefined
  setReportSource: (reportSource: UserInstitution) => void
  settings: UserSettings | null
  updateSettings: {
    setBiometrics: (enabled: boolean) => void
  }
  isSetUp: () => boolean
}

export const UserContext = createContext<State>({
  isAuthenticated: false,
  login: {
    hasAccount: false,
    authenticate: async () => false,
    biometricsAuthentication: () => undefined,
    signUp: () => undefined,
  },
  logout: () => undefined,
  reportSource: null,
  setReportSource: () => undefined,
  settings: {},
  updateSettings: {
    setBiometrics: () => undefined,
  },
  isSetUp: () => true,
})
