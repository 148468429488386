import { useMutation } from '@apollo/client'
import { Box } from '@mantine/core'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'
import { EmailNotificationsCheckboxAbstract } from './EmailNotificationsCheckboxAbstract'

const fragments = {
  EmailNotificationsCheckbox_victim: graphql(`
    fragment EmailNotificationsCheckbox_viewer on CompanyVictim {
      email
      isEmailVerified
      report {
        reportSource {
          config {
            __typename
            ... on FormReportSourceConfiguration {
              emailNotificationEnabled
            }
          }
        }
      }
    }
  `),
}

const mutations = {
  EditEmailCompanyVictim: graphql(`
    mutation EditEmailCompanyVictimMutation($input: EditEmailCompanyVictimInput!) {
      editEmailCompanyVictim(input: $input) {
        companyVictim {
          id
          email
        }
      }
    }
  `),
}

type Props = {
  viewer: FragmentType<typeof fragments.EmailNotificationsCheckbox_victim> | null
}

const EmailNotificationsCheckbox = ({ viewer: _viewer }: Props) => {
  const viewer = getFragmentData(fragments.EmailNotificationsCheckbox_victim, _viewer)
  const [editCompanyEmail] = useMutation(mutations.EditEmailCompanyVictim)
  return (
    <Box
      sx={{
        maxWidth: '400px',
      }}
    >
      <EmailNotificationsCheckboxAbstract
        isEmailNotificationEnabled={
          viewer?.report?.reportSource?.config?.__typename === 'FormReportSourceConfiguration'
            ? viewer.report.reportSource.config.emailNotificationEnabled
            : false
        }
        onSubmit={async ({ email }) => {
          const { data } = await editCompanyEmail({
            variables: {
              input: {
                email,
              },
            },
          })
          return Boolean(data)
        }}
        email={viewer?.email ?? null}
        isEmailVerified={viewer?.isEmailVerified ?? undefined}
      />
    </Box>
  )
}

export default EmailNotificationsCheckbox
