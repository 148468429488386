import { useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { type FragmentType, getFragmentData, graphql } from '../../../../__generated__'
import { type HeaderVariant, desktopBreakpoint } from '../../../../utils/constants'
import { Header as HeaderComponent } from '../../../Header'
import { HeaderComplexWithPages, HeaderComplexWithoutPages, HeaderSimple } from './components'

const fragments = {
  Header_reportSource: graphql(`
    fragment Header_reportSource on PublicReportSource {
      id
      pages(language: $language) {
        ... on Page {
          id
        }
      }
      ...HeaderComplexWithPages_reportSource
      ...HeaderComplexWithoutPages_reportSource
      ...HeaderSimple_reportSource
    }
  `),
}

type HeaderProps = (
  | {
      noMenu?: never
      isMenuOpened: boolean
      setIsMenuOpened: (isOpened: boolean) => void
    }
  | {
      noMenu: true
      isMenuOpened?: never
      setIsMenuOpened?: never
    }
) & {
  reportSource: FragmentType<typeof fragments.Header_reportSource>
  variant: HeaderVariant
}

export const Header = ({
  isMenuOpened,
  setIsMenuOpened,
  reportSource: _reportSource,
  variant,
  noMenu,
}: HeaderProps) => {
  const reportSource = getFragmentData(fragments.Header_reportSource, _reportSource)
  const { breakpoints } = useMantineTheme()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints[desktopBreakpoint]})`)

  if (variant === 'simple-no-back-button') {
    return (
      <HeaderComponent>
        <HeaderSimple reportSource={reportSource} isHiddenBackButton />
      </HeaderComponent>
    )
  }

  if (variant === 'simple') {
    return (
      <HeaderComponent>
        <HeaderSimple reportSource={reportSource} />
      </HeaderComponent>
    )
  }

  if (reportSource.pages.length > 1 && isDesktop && !noMenu) {
    return (
      <HeaderComponent>
        <HeaderComplexWithPages
          reportSource={reportSource}
          isMenuOpened={isMenuOpened}
          setIsMenuOpened={setIsMenuOpened}
        />
      </HeaderComponent>
    )
  }

  return (
    <HeaderComponent>
      {' '}
      {!noMenu && (
        <HeaderComplexWithoutPages
          reportSource={reportSource}
          isMenuOpened={isMenuOpened}
          setIsMenuOpened={setIsMenuOpened}
        />
      )}
    </HeaderComponent>
  )
}
