import { Box, Divider, Footer as MantineFooter } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { type FragmentType, getFragmentData, graphql } from '../../../../__generated__'
import LanguageDropdown from '../../../LanguageDropdown'
import { MenuItems } from '../../../MenuItems'

const fragments = {
  InstitutionLayoutWebFooter_reportSource: graphql(`
    fragment InstitutionLayoutWebFooter_reportSource on PublicReportSource {
      id
      languages
      defaultLanguage

      ...InstitutionLayoutMenuItems_reportSource
    }
  `),
}

type WebFooterProps = {
  reportSource: FragmentType<typeof fragments.InstitutionLayoutWebFooter_reportSource>
  shouldShowLanguagesDropdown: boolean
}

export const WebFooter = ({
  reportSource: _reportSource,
  shouldShowLanguagesDropdown,
}: WebFooterProps) => {
  const reportSource = getFragmentData(
    fragments.InstitutionLayoutWebFooter_reportSource,
    _reportSource
  )
  const { ref: refFooter, height: heightFooter } = useElementSize()
  const { ref: refLanguageDropdown, height: heightLanguageDropdown } = useElementSize()
  const height = heightFooter + (shouldShowLanguagesDropdown ? heightLanguageDropdown : 0)

  return (
    <MantineFooter height={height}>
      <Box
        ref={refLanguageDropdown}
        sx={
          shouldShowLanguagesDropdown
            ? {}
            : {
                visibility: 'hidden',
                position: 'absolute',
                pointerEvents: 'none',
              }
        }
      >
        <Box px='xl' py='sm'>
          <LanguageDropdown
            availableLanguages={reportSource.languages}
            defaultLanguage={reportSource.defaultLanguage}
          />
        </Box>
        <Divider color='gray.2' />
      </Box>

      <Box ref={refFooter}>
        <Box p='xl'>
          <MenuItems reportSource={reportSource} direction='vertical' />
        </Box>
      </Box>
    </MantineFooter>
  )
}
