import { useMutation } from '@apollo/client'
import {
  deserializePin,
  getCurrentEncryptionVersion,
  parsePinToParts,
  prehashPassword,
} from '@faceup/crypto'
import { useContext, useState } from 'react'
import { UserContext } from '../Contexts/UserContext'
import { graphql } from '../__generated__'

const mutation = {
  VictimPreLogin: graphql(`
    mutation VictimPreLogin($input: VictimPreLoginInput!) {
      victimPreLogin(input: $input) {
        salt
        version
      }
    }
  `),
}

export const usePreLogin = () => {
  const { reportSource } = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const [preLoginReport] = useMutation(mutation.VictimPreLogin)

  const performPreLogin = async (
    identity: string,
    password: string,
    version: 1 | 2 = getCurrentEncryptionVersion()
  ) => {
    setIsLoading(true)
    if (version === 1) {
      setIsLoading(false)
      return { passwordKeyPrehash: password, passwordKey: null }
    }

    const preLoginResponse = await preLoginReport({
      variables: {
        input: {
          identity,
          reportPassword: reportSource?.password,
          organizationalUnitId: reportSource?.institutionId ?? '',
        },
      },
    })

    if (!preLoginResponse) {
      setIsLoading(false)
      return { passwordKeyPrehash: password, passwordKey: null }
    }

    const salt = preLoginResponse?.data?.victimPreLogin?.salt ?? ''
    setIsLoading(false)
    const prehashedPassword = await prehashPassword({
      password,
      salt,
    })

    if (prehashedPassword.isErr()) {
      return { passwordKeyPrehash: password, passwordKey: null }
    }

    return prehashedPassword.value
  }

  return {
    performPreLogin: async (reportPin: string) => {
      const pin = deserializePin(reportPin)
      const { identity, version, password } = parsePinToParts(pin)
      return performPreLogin(identity, password, version)
    },
    loading: isLoading,
  }
}
