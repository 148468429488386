import { useLazyQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { useNavigate } from '@faceup/router'
import { Title } from '@mantine/core'
import { Suspense, lazy, useContext } from 'react'
import HeaderCloseIcon from '../../../Components/CloseIcon'
import { FullScreenLoader } from '../../../Components/FullScreenLoader'
import { Header } from '../../../Components/Header'
import PlainLayout from '../../../Components/PlainLayout'
import { LayoutContext } from '../../../Contexts/LayoutContext'
import { UserContext } from '../../../Contexts/UserContext'
import { FormattedMessage, defineMessages } from '../../../TypedIntl'
import { graphql } from '../../../__generated__'

const WebQrScanner = lazy(() => import('./WebQrScanner'))
const AppQrScanner = lazy(() => import('./AppQrScanner'))

const query = {
  InstitutionByQrQuery: graphql(`
    query InstitutionByQrQuery($id: GraphQLString!) {
      publicReportSourceByShortId(id: $id) {
        reportSource {
          id

          institution {
            id
            type
          }
        }

        password
      }
    }
  `),
}

const messages = defineMessages({
  scanQrCode: 'FollowUp.SearchInstitution.scanQrCode',
})

type Props = {
  close: () => void
}

const QrScanner = ({ close }: Props) => {
  const { setReportSource } = useContext(UserContext)
  const { client } = useContext(LayoutContext)
  const navigate = useNavigate()

  const [redirectByShortId] = useLazyQuery(query.InstitutionByQrQuery, {
    onCompleted: ({ publicReportSourceByShortId }) => {
      const reportSource = publicReportSourceByShortId?.reportSource

      if (reportSource) {
        const password = publicReportSourceByShortId?.password ?? ''
        const id = reportSource.id
        const type = reportSource.institution.type

        setReportSource({
          defaultFormId: id,
          institutionId: reportSource.institution.id,
          ...(password && { password }),
          type,
        })
      }

      navigate(routes => routes.page())
    },
  })

  const readQrCode = (code: string) => {
    const decoded = code.match(
      // after `/c/` or `/s/` in URL we allow `w` for `a-z`, `A-Z`, `0-9`, `_` and separated `-`
      /^https:\/\/(www.)?(stage.|demo.)?(faceup.com|nntb.cz|nntb.sk)\/(s|c)\/([\w-]+)$/
    )

    return (decoded ?? []).slice(-1)[0]
  }

  const validateCode = (code: string, stopStream?: () => void) => {
    const id = readQrCode(code)
    if (id) {
      stopStream?.()
      redirectByShortId({ variables: { id } })
    }

    return Boolean(id)
  }

  return (
    <PlainLayout isFooterHidden>
      <Wrapper>
        <Header>
          <Title order={5}>
            <FormattedMessage {...messages.scanQrCode} />
          </Title>
          <HeaderCloseIcon close={close} />
        </Header>
        <Suspense fallback={<FullScreenLoader />}>
          {client === 'app' ? (
            <AppQrScanner validateCode={validateCode} close={close} />
          ) : (
            <WebQrScanner validateCode={validateCode} />
          )}
        </Suspense>
      </Wrapper>
    </PlainLayout>
  )
}

const Wrapper = styled.div`
  position: fixed;
  z-index: 120;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export default QrScanner
