import { UntitledIcon } from '@faceup/icons'
import { ulArrowLeft } from '@faceup/icons/ulArrowLeft'
import { Link } from '@faceup/router'
import { Button } from '@faceup/ui'
import { ChannelType } from '@faceup/utils'
import { ActionIcon, Box, Flex, Group, MediaQuery } from '@mantine/core'
import { useContext } from 'react'
import { LayoutContext } from '../../../../../Contexts/LayoutContext'
import { FormattedMessage, defineMessages } from '../../../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../../../__generated__'
import { useInstitution } from '../../../../../hooks/useInstitution'
import { desktopBreakpoint } from '../../../../../utils/constants'
import { getPageRouteCallback } from '../../../../../utils/urls'
import LanguageDropdown from '../../../../LanguageDropdown'
import { PartnerInstitutionLogo } from './PartnerInstitutionLogo'

const messages = defineMessages({
  backToHomepage: 'FollowUp.header.backToHomepage',
})

const fragments = {
  HeaderSimple_reportSource: graphql(`
    fragment HeaderSimple_reportSource on PublicReportSource {
      id
      languages
      sourceType
      defaultLanguage
      ...PartnerInstitutionLogo_reportSource
    }
  `),
}

type HeaderSimpleProps = {
  reportSource: FragmentType<typeof fragments.HeaderSimple_reportSource>
  isHiddenBackButton?: boolean
}

export const HeaderSimple = ({
  reportSource: _reportSource,
  isHiddenBackButton: _isHiddenBackButton,
}: HeaderSimpleProps) => {
  const reportSource = getFragmentData(fragments.HeaderSimple_reportSource, _reportSource)
  const isHiddenBackButton =
    _isHiddenBackButton ?? reportSource.sourceType === ChannelType.AutomatedHotline
  const { client } = useContext(LayoutContext)
  const institutionContext = useInstitution()
  const isApp = client === 'app'
  const homepageLinkCallback = getPageRouteCallback(institutionContext?.homepage)

  return (
    <Group
      sx={{
        width: '100%',
      }}
      position='apart'
      grow
    >
      <Box>
        {!isApp && !isHiddenBackButton && (
          <>
            <MediaQuery styles={{ display: 'none' }} largerThan={desktopBreakpoint}>
              <ActionIcon component={Link} to={homepageLinkCallback}>
                <UntitledIcon icon={ulArrowLeft} size={24} />
              </ActionIcon>
            </MediaQuery>
            <MediaQuery styles={{ display: 'none' }} smallerThan={desktopBreakpoint}>
              <Button
                component={Link}
                to={homepageLinkCallback}
                variant='text'
                color='dark'
                iconBefore={<UntitledIcon icon={ulArrowLeft} size={24} />}
                data-cy='button-back-to-homepage'
              >
                <FormattedMessage {...messages.backToHomepage} />
              </Button>
            </MediaQuery>
          </>
        )}
      </Box>
      <Flex justify='center'>
        <PartnerInstitutionLogo reportSource={reportSource} />
      </Flex>
      <Flex justify='right'>
        <MediaQuery
          smallerThan={desktopBreakpoint}
          styles={{
            display: 'none',
          }}
        >
          <Box>
            {(reportSource.languages ?? []).length > 1 && (
              <LanguageDropdown
                availableLanguages={reportSource.languages}
                defaultLanguage={reportSource.defaultLanguage}
              />
            )}
          </Box>
        </MediaQuery>
      </Flex>
    </Group>
  )
}
