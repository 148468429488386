import { useLazyQuery } from '@apollo/client'
import { useMatchPath, useNavigate, useParams } from '@faceup/router'
import { ChannelType, type Institution } from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import { useContext, useEffect, useState } from 'react'
import { FullScreenLoader } from '../Components/FullScreenLoader'
import { UserContext } from '../Contexts/UserContext'
import { graphql } from '../__generated__'
import { routes } from '../hooks/useRoutes'
import useRegion from '../utils/useRegion'

type PublicCompanyByShortIdQuery = ResultOf<typeof query.PublicCompanyByShortIdQuery>

const query = {
  PublicCompanyByShortIdQuery: graphql(`
    query PublicCompanyByShortIdQuery($id: GraphQLString!) {
      publicReportSourceByShortId(id: $id) {
        password
        reportSource {
          id
          sourceType
          institution {
            id
          }
        }
      }
    }
  `),
}

const ShortIdRedirect = () => {
  const { shortId } = useParams<'shortId'>()
  const navigate = useNavigate()
  const matchPath = useMatchPath()
  const { setReportSource } = useContext(UserContext)
  const { discoverByShortlink } = useRegion()

  const [reportSourceId, setReportSourceId] = useState<string>()
  const [institutionType, setInstitutionType] = useState<Institution>()

  const defaultAction = (
    publicReportSourceByShortId: NonNullable<
      PublicCompanyByShortIdQuery['publicReportSourceByShortId']
    >
  ) => {
    const password = publicReportSourceByShortId.password

    if (reportSourceId && institutionType) {
      setReportSource({
        defaultFormId: reportSourceId,
        institutionId: publicReportSourceByShortId.reportSource.institution.id,
        type: institutionType,
        ...(password && { password }),
      })
    }

    const params = new URLSearchParams(window.location.search)
    const isPreviewFromAkutan = params.has('testCreate') || params.has('testSent')

    if (matchPath(routes.shortIdCompany) || matchPath(routes.shortIdSchool)) {
      navigate(routes => routes.checkReport())
    } else if (isPreviewFromAkutan) {
      navigate(routes => routes.createReport(), { search: window.location.search })
    } else {
      navigate(routes => routes.page())
    }
    return
  }
  const onCompleteActions: Record<
    ChannelType,
    (a: NonNullable<PublicCompanyByShortIdQuery['publicReportSourceByShortId']>) => void
  > = {
    [ChannelType.AutomatedHotline]: defaultAction,
    [ChannelType.LiveHotline]: defaultAction,
    [ChannelType.ReportingSystem]: defaultAction,
    [ChannelType.Survey]: publicReportSourceByShortId => {
      navigate(routes => routes.survey(publicReportSourceByShortId.reportSource.id), {
        search: window.location.search,
      })
    },
  }
  const [fetchPassword] = useLazyQuery(query.PublicCompanyByShortIdQuery, {
    onCompleted: ({ publicReportSourceByShortId }) => {
      const sourceType = publicReportSourceByShortId?.reportSource.sourceType
      if (!sourceType) {
        return
      }

      const action = onCompleteActions[sourceType]
      action(publicReportSourceByShortId)
    },
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies(shortId):
  // biome-ignore lint/correctness/useExhaustiveDependencies(discoverByShortlink):
  // biome-ignore lint/correctness/useExhaustiveDependencies(navigate):
  // biome-ignore lint/correctness/useExhaustiveDependencies(fetchPassword):
  useEffect(() => {
    const navigateByShortlink = async (shortlink: string) => {
      const discovery = await discoverByShortlink(shortlink)

      if (!discovery) {
        return navigate(routes => routes.page())
      }

      setReportSourceId(discovery.reportSourceId)
      setInstitutionType(discovery.type)

      fetchPassword({
        variables: {
          id: shortlink,
        },
      })
    }

    if (shortId) {
      navigateByShortlink(shortId)
    }
  }, [])

  return <FullScreenLoader />
}

export default ShortIdRedirect
