import { Keyboard } from '@capacitor/keyboard'
import { ulEdit05 } from '@faceup/icons/ulEdit05'
import { ulFileSearch02 } from '@faceup/icons/ulFileSearch02'
import { ulHomeLine } from '@faceup/icons/ulHomeLine'
import { Link, useMatchPath } from '@faceup/router'
import { Institution } from '@faceup/utils'
import { Box, Divider, Footer, Group, UnstyledButton } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { FormattedMessage, defineMessages } from '../../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../../__generated__'
import { useInstitution } from '../../../../hooks/useInstitution'
import { useIsCheckReportAvailable } from '../../../../hooks/useIsCheckReportAvailable'
import { getPageRouteCallback } from '../../../../utils/urls'
import LanguageDropdown from '../../../LanguageDropdown'
import { LogoutButton } from '../../../LogoutButton'
import { ButtonContent } from './components/ButtonContent'

const messages = defineMessages({
  createReport: 'FollowUp.InstitutionLayout.createReport',
  checkReport: 'FollowUp.CheckReport.title',
  createReportForTestingSchool: 'FollowUp.InstitutionLayout.createReportForTestingSchool',
  home: 'FollowUp.InstitutionLayout.home',
})

const fragments = {
  AppFooter_reportSource: graphql(`
    fragment AppFooter_reportSource on PublicReportSource {
      id
      languages
      defaultLanguage
      institution {
        id
        isTesting
        type
      }

      ...IsCheckReportAvailable_reportSource
    }
  `),
}

type AppFooterProps = {
  reportSource: FragmentType<typeof fragments.AppFooter_reportSource>
  shouldShowSecondaryMenu: boolean
}
export const AppFooter = ({
  reportSource: _reportSource,
  shouldShowSecondaryMenu,
}: AppFooterProps) => {
  const reportSource = getFragmentData(fragments.AppFooter_reportSource, _reportSource)
  const { ref: refFooter, height: heightFooter } = useElementSize()
  const { ref: refLanguageDropdown, height: heightLanguageDropdown } = useElementSize()
  const { isCheckReportAvailable } = useIsCheckReportAvailable({ reportSource })
  const matchPath = useMatchPath()
  const [isHidden, setIsHidden] = useState(false)
  const institution = useInstitution()

  useEffect(() => {
    Keyboard.addListener('keyboardWillShow', () => setIsHidden(true))
    Keyboard.addListener('keyboardWillHide', () => setIsHidden(false))

    return () => {
      Keyboard.removeAllListeners()
    }
  }, [])

  if (isHidden) {
    return null
  }

  const height = heightFooter + (shouldShowSecondaryMenu ? heightLanguageDropdown : 0)
  const isTestingSchool =
    reportSource.institution.isTesting === true &&
    reportSource.institution.type === Institution.School

  return (
    <Footer
      height={height}
      sx={{
        position: 'fixed',
      }}
    >
      <Box
        ref={refLanguageDropdown}
        sx={
          shouldShowSecondaryMenu
            ? {}
            : {
                visibility: 'hidden',
                position: 'absolute',
                pointerEvents: 'none',
                // logout button was visible on top of buttons in footer when menu closed
                bottom: -1000,
              }
        }
      >
        <Group px='xl' py='sm' position='apart'>
          <LanguageDropdown
            availableLanguages={reportSource.languages}
            defaultLanguage={reportSource.defaultLanguage}
          />
          <LogoutButton />
        </Group>
        <Divider color='gray.2' />
      </Box>
      <Group ref={refFooter} position='apart' grow spacing={0}>
        {institution?.homepage && (
          <UnstyledButton
            component={Link}
            to={getPageRouteCallback(institution.homepage)}
            c={matchPath(routes => routes.page(), { wildcard: true }) ? 'primary' : undefined}
          >
            <ButtonContent icon={ulHomeLine} label={<FormattedMessage {...messages.home} />} />
          </UnstyledButton>
        )}
        <UnstyledButton
          component={Link}
          to={routes => routes.createReport()}
          c={
            Boolean(matchPath(routes => routes.createReport())) ||
            Boolean(matchPath(routes => routes.sentReport()))
              ? 'primary'
              : undefined
          }
        >
          <ButtonContent
            icon={ulEdit05}
            label={
              isTestingSchool ? (
                <FormattedMessage {...messages.createReportForTestingSchool} />
              ) : (
                <FormattedMessage {...messages.createReport} />
              )
            }
          />
        </UnstyledButton>
        {isCheckReportAvailable && (
          <UnstyledButton
            component={Link}
            to={routes => routes.checkReport()}
            c={
              Boolean(matchPath(routes => routes.report())) ||
              Boolean(matchPath(routes => routes.checkReport(), { wildcard: true }))
                ? 'primary'
                : undefined
            }
          >
            <ButtonContent
              icon={ulFileSearch02}
              label={<FormattedMessage {...messages.checkReport} />}
            />
          </UnstyledButton>
        )}
      </Group>
    </Footer>
  )
}
